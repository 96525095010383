<template>
	<div>
		<v-container>
			<v-row>
				<!-- Inicio Fundo/User -->
				<div class="col-md-12 col-sm-12">
					<v-col
						cols="12"
						md="12"
						style="margin-left: 0px; padding: unset; margin-top: -12px;"
					>
						<s-toolbar :label="'Albarán - ' + item.PrdCardNameResponsable"
							:save="!hiddenBtn"
							@save="save($event)"
							:color="toolbarColor"
							dark
							:load="items.AlbID > 0"
							@load="load"
							close
							@close="close()"
							:sync="items.AlbID > 0"
							@sync="SyncUp()"
							:view="$fun.hasSpecialPermission('viewPrice')"
							@view="viewPrice($event)"
						/>
						
					</v-col>
					<v-card dense :disabled="disabled">
						<v-row style="margin: auto">
							<v-col cols="12">
								<v-row>
									<v-col cols="3" lg="2" > <!-- style="display: flex;" -->
										<s-text
											label="Nro. Lote"
											readonly
											v-model="items.RcfID"
										></s-text>
										<!-- <span style="height: 5px; margin-top: -10px;">
											<v-checkbox
												label="Excluye IGV"
												v-model="items.ExcludeIgv"
											/>
										</span> -->
									</v-col>
									<v-col cols="6" lg="2">
										<s-text
											label="Cultivo"
											readonly
											v-model="items.TypeCultiveName"
										></s-text>
									</v-col>
									<v-col cols="6" lg="2">
										<s-text
											label="Tipo Cultivo"
											readonly
											v-model="items.TypeCropName"
										></s-text>
									</v-col>
									<v-col cols="6" lg="2">
										<s-text
											label="Variedad"
											readonly
											v-model="items.VrtName"
										></s-text>
									</v-col>
									<v-col cols="6" lg="2">
										<s-text
											label="Cod. RC"
											readonly
											v-model="item.PrdCardCodeResponsable"
										></s-text>
									</v-col>
									<v-col cols="6" lg="2">
										<s-text
											label="Nombre RC"
											readonly
											v-model="item.PrdCardNameResponsable"
										></s-text>
									</v-col>
								</v-row>
							</v-col>
						</v-row>

						<v-row style="margin: auto;">
							<v-col cols="12" lg="6" md="6">
								<v-card>
									<v-card-text class="col-md-6"
										style="height: 40px; border-radius: 5px; "
									>
										<b>Lote Calibrado</b>
									</v-card-text>

									<v-card-actions>
										<v-row>
											<v-col cols="4" lg="4" class="pb-0 pt-4">
												<b><v-text-field
														label="Cant. Jabas Cal."
														readonly
														v-model="item.LdhQuantityJaba"
													></v-text-field></b>
											</v-col>

											<v-col cols="4" lg="4" class="pb-0 pt-4">
												<b>
													<v-text-field
														label="Promedio Jaba"
														readonly
														v-model="averageJabas"
														:error="averageJabas < valParamMin || averageJabas > valParamMax"
														:messages="averageJabas < valParamMin || averageJabas > valParamMax ? 'Peso Promedio fuera de Param. establecidos. (Min: ' + valParamMin + ', Max: ' + valParamMax + ')' : ''"
													></v-text-field>
												</b>
											</v-col>

											<v-col cols="4" lg="4" class="pb-0 pt-4">
												<b><v-text-field
														label="Peso Exportable (KG)"
														readonly
														v-model="item.LdhWeightExportable"
													></v-text-field></b>
											</v-col>

											<v-col cols="6" lg="6" class="pb-0 pt-1">
												<b>
													<v-text-field
														label="Descarte Total (KG)"
														readonly
														v-model="item.LdhDiscartTotal"
													></v-text-field>
												</b>
											</v-col>

											<v-col cols="6" lg="6" class="pb-0 pt-1">
												<b>
													<v-text-field
														label="Total Peso"
														v-model="items.AlbQuantityKgNew"
														readonly
														
													>
													</v-text-field>
													<!-- :error="items.AlbQuantityKg >items.LdhWeightExportable"
														:messages="items.AlbQuantityKg > items.LdhWeightExportable ? 'Error en Peso Neto' : ''" -->
												</b>
											</v-col>

											

											<!-- <v-col cols="4" lg="4" class="pb-0 pt-1">
												<b>
													<v-text-field
														label="Diferencia KG"
														readonly
														v-model="differenceKG"
														:error="differenceKG != 0"
														:messages="differenceKG == 0 ? '' : 'Diferencia de KG'"
													></v-text-field>
												</b>
											</v-col> -->
											
											
											
										</v-row>
									</v-card-actions>
								</v-card>
							</v-col>

							<v-col cols="12" lg="6" md="6">
								<v-row>
									<v-col cols="12">
										<v-card>
											<v-card-text 
											class=" col-md-6"
												style="height: 40px; border-radius: 5px;"
											>
												<b>Lote Producción</b>
											</v-card-text>
											<v-card-actions>
												<v-row>
													<v-col cols="6" lg="4" class="pb-0 pt-1">
														<b><v-text-field
															label="Cant, Jabs Recep."
															v-model="items.AmountJabasWeigh"
															readonly
														></v-text-field></b>
													</v-col>
													<v-col cols="6" lg="4" class="pb-0 pt-1">
														<b><v-text-field
															label="Peso Promedio Jaba"
															v-model="items.WbbWeightAverage"
															readonly
														></v-text-field></b>
													</v-col>
													<v-col cols="6" lg="4" class="pb-0 pt-1">
														<b><v-text-field
															label="Peso Neto"
															v-model="items.WbbNetWeight"
															readonly
														></v-text-field></b>
													</v-col>
													
													<!-- <v-col cols="6" lg="3" class="pb-0 pt-1">
														<b><v-text-field
															label="Peso Bruto"
															v-model="items.WbbGrossWeight"
															readonly
														></v-text-field></b>
													</v-col> -->
												</v-row>
											</v-card-actions>
										</v-card>
									</v-col>
									<v-col cols="12" style="margin-top: -10px; display: inline-table;">
										<v-card height="60">
											<v-card-actions>
												<v-col cols="6" lg="6" class="pb-0 pt-1">
													<b style="color: red">Margen de Error (Kg):  </b>{{marginErrorKG}} KG
													<b><h3>{{parseFloat(item.LdhWeightExportable == null ? 0 : item.LdhWeightExportable) + parseFloat(marginErrorKG)}} KG</h3></b>
													
												</v-col>
												<v-col cols="6" lg="6" class="pb-0 pt-1">
													<b style="color: red">Diferencia de KG:  </b>
													<b><h3>{{parseFloat(items.AlbQuantityKgNew == null ? 0 : items.AlbQuantityKgNew) - parseFloat(items.WbbNetWeight == null ? 0 : items.WbbNetWeight)}} KG</h3></b>
													
												</v-col>
											</v-card-actions>
										</v-card>
									</v-col>
								</v-row>
								
							</v-col>
							
							<!-- </v-row>	
						<v-row style="margin: auto;"> -->
						</v-row>
						<v-divider></v-divider>

						<v-col cols="12" lg="12" md="12">
								<v-card>
									<v-card-text
										class=" col-md-6;"
										style="height: 40px; border-radius: 5px;"
									>
										<b>KG x CALIBRE</b>
										<b style="color: blue; font-size: 18px"></b>
									</v-card-text>
									<v-card-actions>
										<!--<v-row>
											<v-col
												v-for="item in Object.keys(itemsCaliber)"
											>
												<h4>{{ item }}</h4>
												<v-divider></v-divider>
												<v-col class="pt-5 pr-0">
													<v-row
														v-for="d in itemsCaliber[item]"
													>
														<v-col
															cols="8"
															class="pb-0 pt-1 pr-0 pl-0"
														>
															<s-text
																:label="d.TypeCaliberName"
																decimal
																:value="d.ClbQuantityKg == null ? 0 : d.ClbQuantityKg.toFixed(2)"
																:min="0"
																@input="CalculateWeight()"
																readonly
															/>
														</v-col>
													</v-row>
												</v-col>
											</v-col>
										</v-row>-->

										<v-row>
                                            <v-col v-for="item in Object.keys(newGroups)" >
                                                <h4>{{ item }}</h4>
                                                <v-divider></v-divider>
                                                <v-col class="pt-5 pr-0">
                                                    <v-row v-for="d in  Object.keys(newGroups[item]) " >
                                                        <v-col cols="12" lg="8"
                                                        class="pb-0 pt-1 pr-0 pl-0">
														<v-chip 
															small 
															:color = getColor(newGroups[item][d])
															> {{ d }}</v-chip>
														<v-chip v-if="viewPrices"
															small >
															{{ getPrice(newGroups[item][d]) }}
														</v-chip>
                                                        <!--<h5 :style="{color : colorPrice}">{{ d }}</h5>-->
                                                        <v-row v-for="(j, i) in newGroups[item][d]"  :key="i">
                                                            <v-col cols="9" lg="9" md="9">
                                                            <!-- <h5>{{ j }}</h5> -->
                                                                <s-text :label="j.TypeCaliberName"
                                                                    decimal
                                                                    :value="j.ClbQuantityKg == null ? 0 : j.ClbQuantityKg.toFixed(2)"
                                                                    :min="0"
                                                                    readonly
																
																	></s-text>
                                                                </v-col>
                                                            <!--<v-col cols="3" lg="3" md="3">
                                                                <s-text label="Cnt. Jabas"
                                                                    decimal
                                                                    :value="j.LppQuantityJaba == null ? 0 : j.LppQuantityJaba"
                                                                    :min="0"
                                                                    readonly
																	
																	></s-text>
																
																	
                                                            </v-col>-->
                                                        </v-row>
                                                        <!-- <s-text :label="d.TypeCaliberName"
                                                            decimal
                                                            v-model="d.ClbQuantityKg == null ? 0 : d.ClbQuantityKg.toFixed(2)"
                                                            :min="0"
                                                            readonly></s-text> -->
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-col>
                                        </v-row>
									</v-card-actions>
								</v-card>
							</v-col>
						
<!-- <v-card :elevation="5" rounded shaped>
						<v-row style="margin: auto;" justify="center">
							<v-col
								cols="6"
								lg="3"
								md="3"
								v-for="(col, indexCol) in itemsQuality"
							>
								<v-card ref="parentDiv" :disabled="col.disabled">
									<v-card-title>
										<span class="text-h6 mt-0 pb-0"><b>{{
											col.CtgDescription
										}}</b></span>
									</v-card-title>
									<v-divider class="mx-4"></v-divider>
									<v-col cols="12" >
										<v-row
											style="margin: auto"
											class="mb-0 mt-3"
											>
											<v-col cols="6" v-for="(row, index) in itemsCaliber[indexCol]">
												<s-text
													cols="12"
													style="margin-bottom: 5px; margin-top: -25px;"
													:label="row.DctgDescription"
													v-model="row.ClbQuantityKg"
													decimal
													@input="CalculateWeight()"
												>
												</s-text>
											</v-col>
										</v-row>
									</v-col>
								</v-card>
							</v-col>
						</v-row>
</v-card> -->
					</v-card>

							
				</div>
			</v-row>

			 <v-dialog v-model="processingSAP" :persistent="messageProcessingSAP.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessingSAP.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
					<v-card-text v-else>
					<div class="text--white pt-4">
						Error al realizar operacion en SAP <br />
						{{ messageProcessingSAP }}
					</div>
					</v-card-text>
				</v-card>
			</v-dialog>

		</v-container>
	</div>
</template>

<script>
	import _sAlbaranService 		from "@/services/FreshProduction/PrfCreateAlbaranService";
	import _sGenParamService 		from "@/services/General/ParameterService";

	export default {
		props: {
			RcfID: {
				required: false,
				default: 0,
			},
			TypeCultive: {
				required: false,
				default: 1,
			},
			item: null,
			items: null,
		},

		data: () => ({
			// items: {
			// 	AlbID: 0,
			// 	RcfID: 0,
			// 	TypeCultive: 0,
			// 	TypeCrop: 0,
			// 	VrtID: 0,
			// 	AlbQuantityKg: 0,
			// 	AlbQuantityKgNew: 0,
			// 	AlbQuantityFruit: 0,
			// 	ExcludeIgv: false,
			// },

			itemsQuality: [],
			itemsCaliber: [],

			disabled: false,
			countItemsCaliber: -1,
			averageJabas: 0,
			differenceKG: 0,

			valParamMin: 0,
			valParamMax: 0,
			messageWeightAverage: "",
			hiddenBtn: false,
			marginErrorKG: 0,
			processingSAP:false,
    		errorSap: false,
    		messageProcessingSAP: "",
			ussUserCode: null,
			isValidatedDiff: 0,
			newGroups:[],
			colorPrice:"",
			loadPrice:[],
			toolbarColor:"grey",
			listItemsCalibers:[],
			viewPrices:false
		}),


		created() {
			this.loadParameter();
			_sAlbaranService
				.GetCaliber(
					{ CtgID: 0, RcfID: this.RcfID, TypeCultive: this.TypeCultive },
						this.$fun.getUserID(),
					)
					.then(r => {
						var dataCaliber = r.data;
						console.log("Itemmmsss",r);
						this.itemsCaliber = _.groupBy(dataCaliber, "TypeLineBusinessText");
									
						this.travelCaliber(this.itemsCaliber);
						console.log("Itemmmsss",this.items);
						console.log("Itemmmsss22222",this.item);
						this.items.AlbQuantityKg = this.items.AlbQuantityKgSend.toFixed(2)//sum.toFixed(2);
						this.items.AlbQuantityKgNew = parseFloat(parseFloat(this.items.AlbQuantityKg == null ? 0 : this.items.AlbQuantityKg) + parseFloat(this.item.LdhDiscartTotal == null ? 0 : this.item.LdhDiscartTotal)).toFixed(2)
						console.log("Totall111111111111",this.items.AlbQuantityKg);
						console.log("Tota222222222",this.items);
						/*var sum  = 0
						for (let prop in this.itemsCaliber) {
							this.itemsCaliber[prop].map((i) => {
								i.RcfID = this.RcfID;
								i.SecStatus = 1
								i.UsrCreateID = this.$fun.getUserID();

								if(i.ClbQuantityKg == ''){
									i.ClbQuantityKg = 0
								}
								sum += parseFloat(i.ClbQuantityKg == null ? 0 : i.ClbQuantityKg)
									
							});
						}
						this.items.AlbQuantityKg = this.items.AlbQuantityKgSend.toFixed(2)//sum.toFixed(2);
						this.items.AlbQuantityKgNew = (parseFloat(this.items.AlbQuantityKg == null ? 0 : this.items.AlbQuantityKg) + parseFloat(this.item.LdhDiscartTotal == null ? 0 : this.item.LdhDiscartTotal)).toFixed(2)
						*/

									
				}); 
 
			
		},

		mounted(){ 
			console.log("item sa", this.item);
			console.log("item sa2", this.item.SecStatus);
			//this.loadParameter();
 
			// if ( !this.item.SecStatus ) { 
			// _sAlbaranService
			// 	.GetCaliber(
			// 		{ CtgID: 0, RcfID: this.RcfID, TypeCultive: this.TypeCultive },
			// 			this.$fun.getUserID(),
			// 		)
			// 		.then(r => {
			// 			var dataCaliber = r.data;
			// 			this.itemsCaliber = _.groupBy(dataCaliber, "CtgDescription");
									
			// 			var sum  = 0
			// 			for (let prop in this.itemsCaliber) {
			// 				this.itemsCaliber[prop].map((i) => {
			// 					i.RcfID = this.RcfID;
			// 					i.SecStatus = 1
			// 					i.UsrCreateID = this.$fun.getUserID();

			// 					if(i.ClbQuantityKg == ''){
			// 						i.ClbQuantityKg = 0
			// 					}
			// 					sum += parseFloat(i.ClbQuantityKg == null ? 0 : i.ClbQuantityKg)

			// 				});
			// 			}
			// 			this.items.AlbQuantityKg = this.items.AlbQuantityKgSend.toFixed(2)//sum.toFixed(2) 

			// 			this.items.AlbQuantityKgNew = (parseFloat(this.items.AlbQuantityKg == null ? 0 : this.items.AlbQuantityKg) + parseFloat(this.item.LdhDiscartTotal == null ? 0 : this.item.LdhDiscartTotal)).toFixed(2);
						
									
			// 	}); 
			// } else { 
				
			// }
			this.initialize(); 
		},

		watch: {
			'items.AlbQuantityKg'(){
				this.calcules()				
			},
			'loadPrice'(newVal1){
				if (Array.isArray(newVal1) && newVal1.length > 0) {
					this.validateItems();
				} else {
				this.toolbarColor = "grey";
				}
			},
			'listItemsCalibers'(newVal) {
				if (Array.isArray(newVal) && newVal.length > 0) {
					this.validateItems();
				} else {
				this.toolbarColor = "grey";
				}
 			}
		},

		


		methods: {
			close() {
				this.$emit("close");
			},

			getColor(j) {
				return this.loadPrice.some((x) => x.CtgID === j[0].CtgID) ? 'success' : '#b7b7b7';
			},

			getPrice(j) {
				if(this.loadPrice.some((x) => x.CtgID === j[0].CtgID))
					return this.loadPrice.find((x) => x.CtgID === j[0].CtgID).CfpAmount; 
				else
					return 0;
			},
			viewPrice(){
				this.viewPrices = !this.viewPrices;
			},

			calcules(){
				console.log("calcules",this.items);
				console.log("itemss",this.item);
				this.averageJabas = (parseFloat(this.items.AlbQuantityKg == null ? 0 : this.items.AlbQuantityKg)  / parseInt(this.item.LdhQuantityJaba)).toFixed(6);
				if(isNaN(this.averageJabas)) {this.averageJabas = 0;}
				this.differenceKG = Math.abs(parseFloat(this.items.AlbQuantityKg == null ? 0 : this.items.AlbQuantityKg) - parseFloat(this.item.LdhWeightExportable == null ? 0 : this.item.LdhWeightExportable)).toFixed(6);
				console.log("total",this.averageJabas);
			},
			
			SyncUp(){
				console.log("this.item", this.item);

				if (this.item.AlbStatusSap == 1) {
					this.$fun
					.alert("Esta seguro de Sincronizar ?" , "question")
					.then(val => {
						if (val.value) {
							_sAlbaranService
								.verifSap(this.items, this.$fun.getUserID())
								.then(r => {
									this.$fun.alert(
										"Sincronizado correctamente",
										"success",
									);
									//this.$refs.AlbaranCrud.refresh();
									this.close();
								});
						}
					});
				}

			},

			travelCaliber(itemsCaliber){
            console.log("items caliberrr",itemsCaliber);
            var sum  = 0
            this.calcules(); // se descomenta PBA
            console.log("Promedio Jaba",this.averageJabas);

			//console.log("load price",this.loadPrice);
			//let listItemsCalibers=[];

            	for (let prop in itemsCaliber) {
					console.log("Descripci111111111111111",prop);
                    this.itemsCaliber[prop].map((i) => {

                        i.RcfID = this.RcfID;
                        i.SecStatus = 1
                        i.UsrCreateID = this.$fun.getUserID();
                       // i.ClsQuantityKg = this.averageJabas * i.LppQuantityJaba //(this.TypeWeight == 1 && this.averageJabas != 0) ? (this.averageJabas * i.LppQuantityJaba) : i.ClsQuantityKg
                        i.prueba = this.TypeWeight == 1   ? "prueba1" : "prueba2";
                        if(i.ClbQuantityKg == ''){
                            i.ClbQuantityKg = 0
                        }
                        sum += parseFloat(i.ClbQuantityKg == null ? 0 : i.ClbQuantityKg)
                            
                    });
                    let description = {};
                    description = _.groupBy(this.itemsCaliber[prop], "CtgDescription");
					console.log("Descripci111111111111111",prop);
					console.log("Descripcionnnnn",description);
					
					for (const atributo in description) {
						description[atributo].forEach(element => {
							console.log("Caliberrr11232323",element);
							this.listItemsCalibers.push(element);
						});
					}
					
                    this.newGroups[prop] = description;
                   console.log("nuevo Group",this.newGroups);

                }
			
          
        	},


			initialize() {
				console.log("iniciando",this.items);	
				if (this.items.AlbStatusSap == 2) {
							this.disabled = true;
							this.$fun.alert(
								"Solo Lectura, Albaran fue migrado a SAP",
								"warning",
							);
						}									
				// _sAlbaranService
				// 	.GetAlbaran({ RcfID: this.RcfID }, this.$fun.getUserID())
				// 	.then(r => {
				// 		this.items = r.data;
				// 		if(this.items.AlbID!=0){
				// 			this.items.AlbQuantityKg=null;
				// 			this.items.AlbQuantityKgNew=null;
				// 		}

				// 		if (this.items.AlbStatusSap == 2) {
				// 			this.disabled = true;
				// 			this.$fun.alert(
				// 				"Solo Lectura, Albaran fue migrado a SAP",
				// 				"warning",
				// 			);
				// 		}						
				// 	});

				_sAlbaranService
					.loadPrice({ RcfID: this.RcfID }, this.$fun.getUserID())
					.then(r => {
						//console.log("Preciossss",r.data);
						this.loadPrice = r.data;
						
					});

				

				/* _sAlbaranService
					.GetQuality(
						{ TypeCultive: this.TypeCultive, RcfID: this.RcfID },
						this.$fun.getUserID(),
					)
					.then(r => {
						var data = r.data;

						if (data.length > 0) {
							if (data[0].CtgID == 0) {
								this.itemsQuality = [];
							} else {
								this.itemsQuality = r.data;
							}

									});

									this.CalculateWeight();
								});
						} else {
							this.$fun.alert(
								"Upps.. Al Parecer no se han registrado  Calidad para el Cultivo: " +
									this.items.TypeCultiveName +
									", Comuniquese con el Area de Produccion.",
								"warning",
							);
						}
					}); */
			},
			validateItems(item){
				const allValid = this.listItemsCalibers.every(item =>this.loadPrice.find((x) => x.CtgID ===item.CtgID ));
				this.toolbarColor = allValid ? "success" : "grey";

			},

			isValidated() {
				var isValidated = true;

				if (this.items.AlbStatusSap == 2) {
					this.$fun.alert(
						"Upps... No cuenta con Permisos para modifcar Albaran.",
						"warning",
					);
					isValidated = false;
					return;
				}

				if (this.items.RcfState < 6 || this.items.RcfState == 11) {
					this.$fun.alert(
						"Para registrar Albaran, el Lote Calibrado debe estar cerrado.",
						"warning",
					);
					isValidated = false;
					return;
				}

				/* if (this.itemsQuality.length == 0) {
					this.$fun.alert("No se han cargado Calidades", "warning");
					isValidated = false;
					return;
				} */

				if (this.itemsCaliber.length == 0) {
					this.$fun.alert("No se han cargado Calibres", "warning");
					isValidated = false;
					return;
				}

				/* if (this.countItemsCaliber == 0) {
					this.$fun.alert(
						"Upps.. Al Parecer no se han registrado  Calibres para alguna de las Calidades correspondiente, Comuniquese con el Area de Produccion.",
						"warning",
					);
					isValidated = false;
					return;
				} */
				//this.isNumber(this.items.AlbQuantityKg);
				if(this.items.AlbQuantityKg == 0 || isNaN(this.items.AlbQuantityKg) == true){
					this.$fun.alert(
						"Total Peso neto calculado es incorrecto",
						"warning",
					);
					isValidated = false;
					return;
				}
				return isValidated;
			},


			save() {

				/* console.log(isNaN(this.items.AlbQuantityKg));
				this.itemsCaliber.forEach(element => {
					element.forEach(details => {
						details.UsrCreateID = this.$fun.getUserID();
						details.SecStatus = 1;
					});
				}); */

				this.items.itemsCaliber = [];
				for (let prop in this.itemsCaliber)
        			this.items.itemsCaliber.push(...this.itemsCaliber[prop]);

				this.items.SecStatus = 1;
				this.items.UsrCreateID = this.$fun.getUserID();

				
				//this.items.itemsQuality = this.itemsQuality;

				/* this.items.itemsQuality.forEach((element, index) => {
					element.QltID = element.QltID;
					element.AlbID = element.AlbID;
					element.UsrCreateID = this.$fun.getUserID();
					element.SecStatus = 1;
					element.itemsCaliber = this.itemsCaliber[index];
				}); */
				console.log(this.items);
				if (!this.isValidated()) {
					return;
				}

				/* if(parseFloat(this.items.AlbQuantityKg) > (parseFloat(this.item.LdhWeightExportable) + parseFloat(this.marginErrorKG))){
					this.$fun.alert("Error, sobrepasa los KG de Peso exportable, consulte con su supervisor", "warning")
					return
				} */

				if(parseFloat(this.items.AlbQuantityKg) < parseFloat(this.item.LdhWeightExportable)){
					this.$fun.alert("Error, Peso Albaran es inferior a Peso exportable, porfavor verifique", "warning")
					return
				}

				if(this.averageJabas < this.valParamMin || this.averageJabas > this.valParamMax){
					this.$fun.alert("Error, Peso Promedio, no esta dentro de los rangos establecidos", "warning")
					return
				}

				this.items.AlbQuantityFruit = this.item.LdhQuantityJaba
				this.items.AlbAverageJaba = this.averageJabas
				
				
				this.$fun
					.alert("Esta seguro de Guardar ?" , "question")
					.then(val => {
						if (val.value) {
							_sAlbaranService
								.save(this.items, this.$fun.getUserID())
								.then(r => {
									this.$fun.alert(
										"Registro guardado correctamente",
										"success",
									);
									//this.$refs.AlbaranCrud.refresh();
									this.close();
								});
						}
					});
			},

			CalculateWeight() {
				/* var suma = 0;

				for (var itemsCaliber of this.itemsCaliber) {
					for (var itemsOne of itemsCaliber) {
						suma += parseFloat(itemsOne.ClbQuantityKg);
					}
				}
				this.items.AlbQuantityKg = suma;
				this.averageJabas = this.items.AlbQuantityKg / this.items.AmountJabasWeigh;
				this.differenceKG = Math.abs(this.averageJabas - this.items.WbbWeightAverage,).toFixed(2); */

				var sum  = 0
				for (let prop in this.itemsCaliber) {
					this.itemsCaliber[prop].map((i) => {
						if(i.ClbQuantityKg == ''){
							i.ClbQuantityKg = 0
						}
						sum += parseFloat(i.ClbQuantityKg == null ?  0 : i.ClbQuantityKg)
					});
				}

				this.items.AlbQuantityKg = this.items.AlbQuantityKgSend.toFixed(2) // sum.toFixed(2)
				this.items.AlbQuantityKgNew = (parseFloat(this.items.AlbQuantityKg == null ? 0 : this.items.AlbQuantityKg) + parseFloat(this.item.LdhDiscartTotal == null ? 0 : this.item.LdhDiscartTotal)).toFixed(2);
				this.averageJabas = (parseFloat(this.items.AlbQuantityKg == null ? 0 : this.items.AlbQuantityKg)  / parseInt(this.item.LdhQuantityJaba)).toFixed(6);
				this.differenceKG = Math.abs(parseFloat(this.averageJabas) - parseFloat(this.items.WbbWeightAverage == null ? 0 : this.items.WbbWeightAverage)).toFixed(6);

				

			},

			load() {
				console.log(this.item);
				if (!this.validatePrices(this.loadPrice,this.listItemsCalibers)) {
					return;
				}

				if(this.isValidatedDiff == 1){
 
					if(parseFloat(this.item.LdhWeightExportable.toFixed(1)) > parseFloat(this.item.LppWeightCalibrated.toFixed(1))){
						console.log("diferencia: LppWeightCalibrated", this.item.LppWeightCalibrated.toFixed(1));
						console.log("diferencia: LdhWeightExportable", this.item.LdhWeightExportable.toFixed(1));
						this.$fun.alert(
							"Error, La distribución guardada en Albáran no coincide con el Peso Exportable, verificar distribución de Calibres.",
							"warning",
						);
						return;
					}
 
				}

				

				this.ussUserCode = this.$fun.getUserSAPInfo().UssUserCode;
				if (this.items.AlbStatusSap == 2) {
					this.$fun.alert(
						"Upps... No cuenta con Permisos para modifcar Albaran.",
						"warning",
					);
					return;
				}

				if (this.ussUserCode == null) {
					this.$fun.alert(
						"No tiene un Usuario SAP autenticado, Comuniquese con el Area de TECNOLOGÍA DE LA INFORMACIÓN.",
						"warning",
					);
					return;
				}
				
				this.$fun.alert("Seguro de subir a SAP ? Productor a subir ==> " + this.item.PrdCardNameResponsable + "", "question")
				.then(r => {
					if(r.value){
						this.processingSAP = true;
						
						_sAlbaranService
						.loadsapalbaran(this.item, 284, this.$fun.getUserID())
							.then(resp => {
								if (resp.status == 200) {
									this.processingSAP = false;
									this.$fun.alert("Subido Correctamente", "success");
									this.close();
								}
							},
							(e) => {
								this.errorSap = true;
								this.messageProcessingSAP = e.response.data.Message;
								this.processingSAP = true;
							}
						
						);
					}
				})
				
			},

			loadParameter() {

				_sGenParamService
					.search(
						{ PrmName: "CalibradoMargenErrorKgAlbaran" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						this.marginErrorKG = resp.data.PrmValue;
					});


				_sGenParamService
					.search(
						{ PrmName: "verificaPesoExportAlbaran" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						this.isValidatedDiff = resp.data.PrmValue;
					});

				


				this.valParamMin = this.item.WpcMin
				this.valParamMax = this.item.WpcMax

				if(this.valParamMin == null || this.valParamMax == null){
					this.$fun.alert("Advertencia, no se ha configurado parametros Peso Promedio (Min - Max)", "warning")
					this.hiddenBtn = true
				}else{
					this.hiddenBtn = false
				}
			},

			validatePrices(loadPrice,itemsCalibers) {
				// Filtrar loadPrice para encontrar los CtgID que se repiten
				const groupedByCtgID = loadPrice.reduce((acc, price) => {
					acc[price.CtgID] = (acc[price.CtgID] || 0) + 1;
					return acc;
				}, {});

				// Filtrar los CtgID repetidos
				const repeatedCtgIDs = Object.keys(groupedByCtgID).filter(ctgID => groupedByCtgID[ctgID] > 1);

				if (repeatedCtgIDs.length > 0) {
					// Buscar descripciones asociadas a los CtgID repetidos solo si están en itemsCalibers
					const conflicts = repeatedCtgIDs
						.map(ctgID => {
							// Filtrar itemsCalibers que tienen el mismo CtgID
							const associatedItems = itemsCalibers.filter(item => item.CtgID === parseInt(ctgID));

							// Obtener las descripciones asociadas
							const descriptions = associatedItems.map(item => item.CtgDescription);

							return {
								ctgID,
								descriptions: [...new Set(descriptions)], // Eliminar duplicados
								isValid: associatedItems.length > 0, // Solo considerar los que tienen asociación
							};
						})
						.filter(conflict => conflict.isValid); // Ignorar los que no tienen asociación

					if (conflicts.length > 0) {
						// Generar mensaje de alerta para conflictos encontrados
						let message = "Se han configurado Precios para la misma Categoría:\n\n";
						conflicts.forEach(conflict => {
							message += `CtgID: ${conflict.ctgID} - Descripciones: ${conflict.descriptions.join(", ")}\n`;
						});

						this.$fun.alert(message, "warning");
						return false;
					} else {
						console.log("No se encontraron conflictos en los precios configurados.");
						return true;
					}
				} else {
					console.log("No se encontraron conflictos en los precios configurados.");
					return true;
				}
			}
		},
	};
</script>

<style></style>
