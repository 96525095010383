<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" lg="12" md="12">
					<template>
						<s-crud 
							:config="config"
							:filter="filter"
							@rowSelected="rowSelected($event)"
							ref="AlbaranCrud"
							search-input
							no-full
							title="Registro de Albaran"
							height="auto"
							excel
						>
							<template v-slot:filter>
								<v-container>
									<v-row justify="center" style="margin-left: 5px">
										<v-col cols="6" md="6" lg="3">
											<s-date
												label="Initial date"
												v-model="filter.cDateInitial"
											></s-date>
										</v-col>
										<v-col cols="6" md="6" lg="3">
											<s-date
												label="End date"
												v-model="filter.cDateFin"
											></s-date>
										</v-col>

										<v-col cols="12" md="12" lg="3">
											<s-select-definition
												clearable
												:def="1230"
												label="Planta Procesadora"
												v-model="filter.TypeProcessingPlant"
											/>
										</v-col>
										<v-col>
											<s-select-definition
												clearable
												:def="1237"
												label="Estado SAP"
												v-model="filter.AlbStatusSap"
											/>
										</v-col>
									</v-row>
									<v-row
										justify="center"
										style="margin-left: 5px"
									>
										<v-col cols="6" md="6" lg="4">
											<s-select-definition
												clearable
												:def="1172"
												label="Tipo Cultivo"
												v-model="filter.TypeCrop"
											/>
										</v-col>

										<v-col cols="6" md="6" lg="4">
											<s-select-definition
												clearable
												style=""
												:def="1173"
												label="Cultivo"
												v-model="filter.TypeCultive"
											/>
										</v-col>

										<v-col cols="12" md="12" lg="4">
											<s-select-variety
												clearable
												:cultiveID="filter.TypeCultive"
												label="Variedad"
												full
												v-model="filter.VrtID"
											/>
										</v-col>
										
									</v-row>

									<v-row style="margin-left: 5px">
										<v-col cols="6" sm="2">
											<v-btn
												style="color:white"
												small
												rounded
												block
												color="info"
												@click="openAlbaran()"
												:disabled="disabledBtn"
											>
												Albaran
											</v-btn>
										</v-col>
										<v-col cols="6" sm="2">
											<v-btn
												v-if="isLevelAdmin"
												style="color:white"
												small
												rounded
												block
												color="warning"
												@click="anulateAlbaran()"
												:disabled="disabledBtn"
											>
												Anular Albaran
											</v-btn>
										</v-col>
										<!-- <v-col
											cols="12"
											class="s-col-form"
											xs="4"
											sm="4"
											md="4"
										>
											<s-switch
												label="Filtrar todos"
												
												v-model="filter.All"
											></s-switch>
										</v-col> -->

										<!-- <v-col cols="6" sm="3"> </v-col>
										<v-col cols="6" sm="3"> </v-col> -->
									</v-row>
								</v-container>
							</template>

							<template v-slot:SecStatus="{ row }">
								<v-tooltip bottom="">
									<template v-slot:activator="{ on }">
										<v-chip
											v-on="row.DocNumSap != null ? on : {}"
											x-small
											:color="row.SecStatus == 1 ? 'success' : 'warning'"
										>
											{{ row.SecStatus == 1 ? "Si" : "No" }}
										</v-chip>
									</template>
									<span>{{ row.DocNumSap }}</span>
								</v-tooltip>
								<br>
								<button v-if="$fun.isAdmin()" @click="searchData(row)"><i class="fas fa-edit"></i></button>
							</template>

							<template v-slot:LdhDiscardDamage="{ row }">
								<v-chip
									x-small
									:color="row.LdhDiscardDamage > 0 ? 'success' : 'error'"
								>
									{{ row.LdhDiscardDamage > 0 ? "Si" : "No" }}
								</v-chip>
							</template>

							<template v-slot:TypeAlertName="{ row }">
								<v-chip
									x-small
									:color="row.TypeAlertColor"
								>
									{{ row.TypeAlertName }}
								</v-chip>
							</template>

							<template v-slot:AlbStatusSap="{ row }">
								<v-chip
									x-small
									:color="row.AlbStatusSap == 1 ? 'error' : 'info'"
								>
									{{ row.AlbStatusSap == 1 ? 'Sin Subir' : 'Subido' }}
								</v-chip>
							</template>

							<template v-slot:options>
								<div>
									<v-btn
										v-if="$fun.isAdmin()"
										x-small 
										color="info" 
										@click="enabledLot()"
									>	
										<i class="far fa-stop-circle"></i>Habilitar Lote
									</v-btn>
								</div>
							</template>
							<template v-slot:RcfID="{ row }">
								 {{row.RcfID + "-" + row.RcfCode}}
							</template>
						</s-crud>
					</template>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog
			v-model="openDialog"
			v-if="openDialog"
			scrollable
			
			persistent
			fullscreen
			transition="dialog-bottom-transition"
		>
			<create-albaran
				:RcfID="items.RcfID"
				:TypeCultive="items.TypeCultive"
				:item="items"
				:items="objAlbaran"
				@close="close()"
			>
			</create-albaran>
		</v-dialog>

		<v-dialog
			v-model="openDialogEdit"
			v-if="openDialogEdit"
			scrollable
			width="900"
			
			persistent
			transition="dialog-bottom-transition"
		>
		<v-card class="mx-auto">
			<s-toolbar label="Editar Productor" dark color="#8e8f91" close @close="openDialogEdit = false"></s-toolbar>
			<v-container>
				<s-toolbar label="Productor SunExpert" dark color="#8e8f91"></s-toolbar>
				<v-container style="padding: 0%;">
					<!-- <v-row> -->
						<!-- <v-col> -->
							<v-data-table
								item-key="PrdID"
								dense
								fixed-header=""
								:items="dataDriverSunExpert"
								:headers="headersSearchSunExpert"
								:items-per-page="10"
								@click:row="rowClickSearch"
								v-model="selectedSearch"
							>
							<template v-slot:item.AsgCodeSap="{ item }">
                                <v-btn
                                color="success"
                                x-small
                                @click="AsgCodeSapUpdate(item)"
                                block
                                >
                                <v-icon small>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
						</v-data-table>
						<!-- </v-col> -->
					<!-- </v-row> -->
				</v-container>
				<hr style="margin: 20px 0px;">
				<s-toolbar label="Productor SAP" dark color="#8e8f91"></s-toolbar>
				<v-container style="padding: 0%;">
					<!-- <v-row> -->
						<!-- <v-col> -->
							<v-data-table
								item-key="CardCode"
								dense
								fixed-header=""
								:items="dataDriverSap"
								:headers="headersSearchSap"
								:items-per-page="10"
								@click:row="rowClickSearchSap"
								v-model="selectedSearchSap"
							></v-data-table>
						<!-- </v-col> -->
					<!-- </v-row> -->
				</v-container>
			</v-container>
		</v-card>
		</v-dialog>
	</div>
</template>
<script>
	import _sAlbaranService from "../../../services/FreshProduction/PrfCreateAlbaranService";
	import CreateAlbaran from "@/views/FreshProduction/CreateAlbaran/CreateAlbaran.vue";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	
	export default {
		components: {
			CreateAlbaran,
			SSelectVariety,
		},
		data: () => ({
			AsgCodeSap: null,
			rowRcfID: {},
			dataDriverSunExpert: [],
			dataDriverSap: [],
			filterOne: 0,
			isLevelAdmin: false,
			items: {},
			objAlbaran:{},
			openDialog: false,
			openDialogEdit: false,
			selectedSearch: [],
			selectedSearchSap: [],
			PrdCardCodeRc: null,
			RcfID: null,
			headersSearchSunExpert: [
                {text: "PrdID"									, value: "PrdID"},
                {text: "Nombres y Apellidos"				, value: "PrdCardName"},
                {text: "Code"								, value: "PrdCardCode"},
                {text: "Tipo Proveedor"						, value: "TypeSupplierName"},
                {text: "Actualizar"							, value: "AsgCodeSap"},
            ],
			headersSearchSap: [
                {text: "Code"								, value: "CardCode"},
                {text: "Nombres y Apellidos"				, value: "CardName"},
            ],
			filter: {
				cDateInitial: null,
				cDateFin: null,
				TypeProcessingPlant: null,
				TypeCrop: null,
				VrtID: null,
				TypeCultive: null,
				AlbStatusSap: null,
				/* All: 1, */
			},
			config: {
				service: _sAlbaranService,
				model: {
					RcfID: "ID",
					SecStatus: "",
					TypeAlertName: "",
					LdhDiscardDamage: "",
					AlbStatusSap : ""
				},
				headers: [
					{ text: "Albaran", value: "SecStatus",   align: "center"},
					{ text: "Muestreo daños", value: "LdhDiscardDamage", align: "center" },
					{ text: "Nro. Lote Prod.", value: "RcfID", width: 100 },
					{ text: "Productor", value: "PrdCardNameResponsable", width: 360 },
					/* { text: "Productor Responsable Cobrar", value: "PrdCardNameResponsable", width: 360 }, */
					{ text: "ID FRUTA", value: "FltID" },
					{ text: "Fundo", value: "FagName", width: 360 },
					{ text: "Lote", value: "FltName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
					{ text: "Variedad", value: "VrtName", width: 160 },
					{ text: "Nro. Jabas", value: "AmountJabasWeigh", width: 160 },
					{ text: "SAP", value: "AlbStatusSap", width: 160 },
					{ text: "Alerta", value: "TypeAlertName", width: 160 },
					{
						text: "Planta Procesadora",
						value: "TypeProcessingPlantName",
						width: 160,
					},
					{ text: "Empacadora", value: "TypeBalerName", width: 160 },
				],
			},

			disabledBtn: true,
		}),

		created() {
			if (this.$fun.getSecurity().IsLevelAdmin) {
				this.isLevelAdmin = true;
			} else {
				this.isLevelAdmin = false;
			}
		},

		methods: {
			AsgCodeSapUpdate(item) {
				// console.log("AsgCodeSapUpdate",item);

				if(this.selectedSearchSap != null && this.selectedSearchSap.length > 0 && this.RcfID != null){
					this.$fun.alert('Seguro de actualizar?', 'question')
					.then(resp => {
						if(resp.value){
							// console.log("Actualizando", this.selectedSearchSap[0].CardCode);
							let objAlbaran = {};
							objAlbaran.PrdID = this.selectedSearch[0].PrdID;
							objAlbaran.PrdCardCode = this.selectedSearchSap[0].CardCode;
							objAlbaran.RcfID = this.RcfID;
							// console.log("objAlbaran",objAlbaran);
							_sAlbaranService.updatePrfReceptionFresh(objAlbaran, this.$fun.getUserID())
							.then((e) => {               
								if (e.status == 200) { 
									this.searchData(this.rowRcfID);
									// console.log("updatePrfReceptionFresh:",e.data);
									this.$fun.alert("Actualizado Correctamente", "success")
								}
							});
						}
					});
				}
				else{
					this.$fun.alert('Seleccione un registro de SAP', 'warning')
				}
			},
			rowClickSearch: function(item, row) {
				// console.log("item Desde la tabla:",item);
				if (item !== undefined) {
					this.selectedSearch = [item];
					// if(item == this.selectedSearch[0]){
					// 	this.selectedSearch = [];
					// }else{
					// }
				}
			},
			rowClickSearchSap: function(item, row) {
				// console.log("item Desde la tabla:",item);
				if (item !== undefined) {
					if(item == this.selectedSearchSap[0]){
						this.selectedSearchSap = [];
					}else{
						this.selectedSearchSap = [item];
					}
				}
			},
			async searchData(row) {
				console.log("Data: ",row);

				this.rowRcfID = {};
				this.RcfID = null;

				this.selectedSearch = [];
				this.selectedSearchSap = [];

				this.dataDriverSunExpert = [];
				this.dataDriverSap = [];

				await _sAlbaranService.getDataPrfReceptionFresh(row.RcfID, this.$fun.getUserID())
				.then((r) => {               
					if (r.status == 200) { 
						console.log("getDataPrfReceptionFresh:",r.data[0]);
						this.dataDriverSunExpert = r.data;
						let PrsDocumentNumberBase = r.data[0].PrdCardCode;
						let PrsDocumentNumber = PrsDocumentNumberBase.replace(/[a-zA-Z]/g, '').replace(/^0+/, '');
						// console.log("PrsDocumentNumber:",PrsDocumentNumber);
						this.rowRcfID = row;
						this.RcfID = row.RcfID;

						_sAlbaranService.getDataTasProducer(PrsDocumentNumber, this.$fun.getUserID())
						.then((e) => {               
							if (e.status == 200) { 
								// console.log("getDataTasProducer:",e.data);
								const TasProducer = e.data.filter(item => item.CardType === "S");
								this.dataDriverSap = TasProducer;
								this.openDialogEdit = true;
								// console.log("TasProducer:",TasProducer);
							}
						});
					}
				});
			},
			close() {
				this.openDialog = false;
				this.$refs.AlbaranCrud.refresh();
			},
			rowSelected(val) {
				// console.log("Item",val);
				if(val.length > 0) {
					if (val !== undefined) {
						this.items = val[0];
						
						if(this.items.LdhDiscardDamage > 0){
							this.disabledBtn = false
						}else{
							this.disabledBtn = true
						}
					}
				}
				
			},

			isValidated() {
				var isValidated = true;
				if (this.items == undefined) {
					this.$fun.alert("No existen registro", "warning");
					isValidated = false;
					return;
				}

				if (this.items.length == 0) {
					this.$fun.alert("No existen registro", "warning");
					isValidated = false;
					return;
				}

				if (this.items.RcfID == 0) {
					this.$fun.alert("No se ha seleccionado registro", "warning");
					isValidated = false;
					return;
				}

				return isValidated;
			},
			
			openAlbaran() {
				if (!this.isValidated()) {
					return;
				}

				_sAlbaranService
					.GetAlbaran({ RcfID: this.items.RcfID }, this.$fun.getUserID())
					.then(r => {
						this.objAlbaran = r.data;
						// console.log("Albaran",this.objAlbaran);
						this.openDialog = true;						
					});
				
			},

			/* filterAll(val) {
				//alert(val)
				@input="filterAll($event)"
				this.filter = { All: this.filterOne };
				console.log("Filtrt todo ", this.filter);
			}, */

			anulateAlbaran() {
				
				if (!this.isValidated()) {
					return;
				}

				if (this.items.AlbID == 0) {
					this.$fun.alert(
						"Upps.. No se ha registrado albaran para el Lote de Producción seleccionado",
						"warning",
					);
					return;
				}
				if (this.items.AlbStatusSap == 2) {
					this.$fun.alert(
						"No se puede anular, Albaran ya fue subio a SAP",
						"warning",
					);
					return;
				}

				this.$fun
					.alert(
						"Esta seguro de Anular **ALBARAN** ? al Fundo: " +
							this.items.FagName,
						"question",
					)
					.then(val => {
						if (val.value) {
							_sAlbaranService
								.anulateAlbaran(this.items, this.$fun.getUserID())
								.then(r => {
									if ((r.status = 200)) {
										this.$fun.alert(
											"Albaran anulado correctamente",
											"success",
										);
										this.$refs.AlbaranCrud.refresh();
									}
								});
						}
					});
			},


			enabledLot(){
				if (this.items.AlbStatusSap == 2) {

					this.items.enabledSAP = 10
					

					this.$fun
					.alert(
						"Seguro de Habilitar LP para reingreso a SAP ?" +
							this.items.FagName,
						"question",
					)
					.then(val => {
						if (val.value) {
							
							_sAlbaranService
								.save(this.items, this.$fun.getUserID())
								.then(r => {
									if ((r.status = 200)) {
										this.$fun.alert(
											"Albaran anulado correctamente",
											"success",
										);
										this.$refs.AlbaranCrud.refresh();
									}
								});
						}
					});

				}else{
					this.$fun.alert(
											"LP aun no se a migrado a SAP",
											"warning",
										);
				}
			},
		},
	};
</script>
