import { update } from "lodash";
import Service from "../Service";
import Vue from "vue";
const resource = "albaron/"

export default {

    GetAlbaran(obj, requestID) {
        return Service.post(resource + "GetAlbaran", obj, {
            params: { requestID: requestID },
        });
    },

    GetQuality(obj, requestID) {
        return Service.post(resource + "GetQuality", obj, {
            params: { requestID: requestID },
        });
    },

    GetCaliber(obj, requestID) {
        return Service.post(resource + "GetCaliber", obj, {
            params: { requestID: requestID },
        });
    },

    anulateAlbaran(obj, requestID) {
        return Service.post(resource + "AnulateAlbaran", obj, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "Save", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID }
        });
    },

    loadPrice(obj, requestID) {
        return Service.post(resource + "loadPrice", obj, {
            params: { requestID: requestID }
        });
    },


    loadsapalbaran(alb, UsrID, requestID) {
        return Service.post(resource + 'loadsapalbaran', alb, {
            params: { UssUserID: UsrID, requestID }
        });
    },


    verifSap(alb, requestID) {
        return Service.post(resource + 'verifSap', alb, {
            params: { requestID }
        });
    },

    GetCaliberSAP(obj, requestID) {
        return Service.post(resource + "GetCalibersap", obj, {
            params: { requestID: requestID },
        });
    },

    excel(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    getDataTasProducer(PrsDocumentNumber, requestID) {
        return Service.post(resource + "getDataTasProducer", {}, {
            params: { PrsDocumentNumber:PrsDocumentNumber, requestID: requestID },
        });
    },

    getDataPrfReceptionFresh(RcfID, requestID) {
        return Service.post(resource + "getDataPrfReceptionFresh", {}, {
            params: { RcfID:RcfID, requestID: requestID },
        });
    },

    updatePrfReceptionFresh(objAlbaran, requestID) {
        return Service.post(resource + "updatePrfReceptionFresh", objAlbaran, {
            params: { requestID: requestID },
        });
    }
};